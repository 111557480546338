// import { styled } from "@mui/material/styles";
import MuiButton from '@mui/material/Button';
import colors from '@studygenius/shared/Colors';
import { Typography, Link, Button, Box, lighten, darken, Grid } from '@mui/material';
import { MUIStyles } from '@studygenius/shared/Variables';

export const boxShadowStyle = {
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12), ' +
                   '0px 2px 2px rgba(0, 0, 0, 0.14), ' +
                   '0px 3px 1px -2px rgba(0, 0, 0, 0.2)'
};

export const ButtonChild = ({children, sx, loading = false}) => {
  console.log('typeof children', typeof children)
  return (
  typeof children === 'string' ? <Typography sx={{...MUIStyles.buttonText, ...sx}}>{children}</Typography> : children)
};


export function slugify(title) {
  return title
    .toString()                       // Convert to string
    .normalize('NFD')                 // Split accents and special characters
    .replace(/[\u0300-\u036f]/g, '')  // Remove diacritics
    .toLowerCase()                    // Convert to lowercase
    .trim()                           // Remove whitespace
    .replace(/[^a-z0-9]+/g, '-')      // Replace non-alphanumeric with hyphens
    .replace(/^\d+/, '')              // Remove leading numbers
    .replace(/^-+/, '')               // Remove leading hyphens resulting from number removal
    .replace(/-+$/g, '');             // Remove trailing hyphens
}

export const defaultColors = {
    hover: '#DC7527',
    whiteHover: '#F0F0F0',
    disabled: 'rgba(0,0,0,0.38)',
    error: '#D32F2F',
    errorBackground: 'rgba(211, 47, 47, 0.1)',
    errorHover: '#C62828',
    greenHover: '#37BFBFCC',
    delete: '#D32F2F',
    deleteBackground: 'rgba(255, 0, 0, 0.15)',
    primaryTransparent: 'rgba(228,	153,	51, 0.15)',
    primaryMediumOpacity: 'rgba(228,	153,	51, 0.3)',
    primary50Opacity: 'rgba(228,	153,	51, 0.5)',
    link: '#4A3AFF',
    linkHover: 'rgba(74, 58, 255, 0.15)',
    linkFocus: 'rgba(74, 58, 255, 0.3)',
    error2: '#F3736C',

  




};

const buttonHOC = (getColors, buttonStyleFn) => ({ children, buttonSx, onClick, sx, disabled, Icon, AfterIcon, ...otherProps }) => {
  
    const {background, hover, childSx, additionalSx, focus, boxShadow} = getColors(disabled);
  
    return (
        <Button onClick={onClick} disabled={disabled} {...otherProps} 
            sx={{
                padding: '0px 22px 0px 22px',
                backgroundColor: background,
                minHeight: '44px',
           
             
                '&:hover': {
                    backgroundColor: hover,
                },
                '&:focus': {
                    backgroundColor: focus,
                },
                ...additionalSx,
                ...sx
            }}>
            <>
                {Icon && <Icon sx={{...childSx, ...buttonSx}} />}

                <ButtonChild sx={{...childSx, ...buttonSx}}>{children}</ButtonChild>
                {AfterIcon && <AfterIcon sx={{...childSx, ...buttonSx}} />}

            </>
        </Button>

    );
};

export function escapeDollarSignsOutsideMath(content) {
    // Regular expression to find math expressions ($...$ or $$...$$)
    const mathRegex = /\${2}(?!\d)[\s\S]*?\${2}|\$(?!\d)[\s\S]*?\$/g;
  
    // Split content into segments outside and inside math expressions
    const segments = [];
    let lastIndex = 0;
    let match;
  
    while ((match = mathRegex.exec(content)) !== null) {
      // Add non-math text before the math expression
      if (match.index > lastIndex) {
        segments.push({ isMath: false, text: content.slice(lastIndex, match.index) });
      }
      // Add the math expression
      segments.push({ isMath: true, text: match[0] });
      lastIndex = mathRegex.lastIndex;
    }
  
    // Add any remaining text after the last math expression
    if (lastIndex < content.length) {
      segments.push({ isMath: false, text: content.slice(lastIndex) });
    }
  
    // Process segments
    const processedSegments = segments.map((segment) => {
      if (segment.isMath) {
        // Leave math expressions unchanged
        return segment.text;
      } else {
        // Escape all dollar signs in non-math text to prevent misinterpretation
        return segment.text.replace(/\$/g, '&#36;');
      }
    });
  
    // Reassemble the content
    return processedSegments.join('');
  }

export const PrimaryButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : colors.main,
    hover: disabled ? defaultColors.disabled : defaultColors.hover,
    additionalSx: boxShadowStyle
}));


export const OutlinedPrimaryOpacityButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : 'transparent',
    hover: disabled ? defaultColors.disabled : defaultColors.whiteHover,
    childSx: {color: defaultColors.primary50Opacity},
    additionalSx: primarySx
}));

export const SectionWrapper = ({text, sx={}, headerSx={}, children}) => {
    return (<Box sx={{marginX: 2, marginBottom: 3, borderTop: {xs: 'none', md: `1px solid ${colors.text}`},...sx}}>
        <Typography sx={{...MUIStyles.header, width: '100%', textAlign: 'center', marginTop: 3, ...headerSx}}>{text}</Typography>
        {children}
    </Box>)
  }
  
  export const RenderCard = ({item, md=2}) => {
    return (<Grid item xs={4} md={md} key={item.id} sx={{
         minWidth: { xs: '160px', sm: '200px' }, 
       }}>
           <Box sx={{margin: 1, marginX: {xs: 0, md: 1}, position: 'relative', height: {xs: '170px', md: '230px'}, borderRadius: '8px'}}>
           
         <Link href={item.link ||`/courses/${item.id}`}>
         <Box sx={{...MUIStyles.hoverEffect}} />
         <img src={item.src || '/forest.jpg'} alt={item.title}  style={{ width: '100%', height: '100%', borderRadius: '16px', objectFit: 'cover'}} />
           {/* <img src={item.src || 'forest.jpg'} alt={item.text} style={{ width: '100%', height: '100%', borderRadius: '16px', objectFit: 'cover'}} /> */}
           
           <Box sx={{backgroundColor: 'rgba(255,255,255, 0.7)', height: '85px', position: 'absolute', bottom: 0, width: '100%', overflow: 'hidden'}}>
           <Typography sx={{...MUIStyles.paragraph, margin: 1, whiteSpace: 'pre-line' }}>{item.title}</Typography>
  
           </Box>
         </Link>
         </Box>
       </Grid>)
  }
  
  
  export const CardsWrapper = ({ children, sx }) => {
    return (
      <Box
        sx={{
          width: {xs: 'calc(100vw - 0px)', md: 'calc(100vw - 48px)'}, // Ensure it does not exceed the screen width
          overflowX: 'auto', // Allows horizontal scrolling
          '&::-webkit-scrollbar': {
            display: { xs: 'none', md: 'block' }, // Hide scrollbar on small devices for cleaner look
          },
          // Fix height to ensure only this component scrolls
        //   maxHeight: '100vh',
          marginLeft: {xs: '-16px', md: 0},
          overflowY: 'hidden', // Prevent vertical scrolling inside this component
          ...sx,
        }}
      >
        <Grid container spacing={2} sx={{
          flexWrap: { xs: 'nowrap', md: 'wrap'}, 
          display: 'flex',
          padding: 2,
        }}>
          {children}
        </Grid>
      </Box>
    );
  };



export const PrimaryWhiteButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : colors.white,
    hover: disabled ? defaultColors.disabled : defaultColors.whiteHover,
    additionalSx: boxShadowStyle,

    childSx: {color: disabled ? colors.white : colors.main}
}));

export const PrimaryErrorButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : defaultColors.error,
    hover: disabled ? defaultColors.disabled : defaultColors.errorHover,
    childSx: {color: colors.white}
}));


const greenSx = { border: `1px solid ${colors.lightGreen}`, borderColor: colors.lightGreen};

const primarySx = { border: `1px solid ${defaultColors.primary50Opacity}`, borderColor: defaultColors.primary50Opacity};

const errorSx = { border: `1px solid ${defaultColors.error}`, borderColor: defaultColors.error};

export const OutlinedErrorButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : 'transparent',
    hover: disabled ? defaultColors.disabled : defaultColors.whiteHover,
    childSx: {color:  defaultColors.error},
    additionalSx: errorSx
}));

export const PrimaryGreenButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : colors.lightGreen,
    hover: disabled ? defaultColors.disabled : '#2C9999',
    childSx: {color: colors.text},
}));

export const OutlinedGreenButton = buttonHOC((disabled)=>({
    background: disabled ? defaultColors.disabled : 'transparent',
    hover: disabled ? defaultColors.disabled : defaultColors.whiteHover,
    childSx: {color: colors.lightGreen},
    additionalSx: greenSx
}));




export const PrimaryLink = ({children, sx, href, ...otherProps}) => <Link 
    sx={{...MUIStyles.mediumParagraph, color: colors.text, display:'block', textDecoration: {xs: 'none', md: 'auto'},
        '&:hover': {
            color: {xs: defaultColors.link, md: colors.main},
            textDecoration: {xs: 'none', md: 'underline'}
        },
        ...sx
    }}
    {...otherProps} 
    href={href}>{children}
</Link>;

// export const SecondaryLink = ({children, sx, href, ...otherProps}) => <Link 
// sx={{...MUIStyles.mediumParagraph, color: '#4A3AFF', display:'block', textDecoration: 'auto',
// '&:hover': {
//   color: colors.main,
//   textDecoration: 'underline'
// },
// ...sx
// }}
// {...otherProps} 
// href={href}>{children}
// </Link>


// export const PrimaryWhiteButton = ({children, buttonSx, onClick, sx, disabled, ...otherProps}) => {

//   return (
//     <Button onClick={onClick} disabled={disabled} {...otherProps} 
//     sx={{
//       backgroundColor: disabled ? defaultColors.disabled : colors.white ,
//       '&:hover': {
//         backgroundColor: disabled ? defaultColors.disabled : defaultColors.whiteHover,
//       },
//       ...sx
//     }}>
//          <ButtonChild sx={{color: colors.main, buttonSx}}>{children}</ButtonChild>

//     </Button>
//   );
// };

export const DeleteTransparentButton = buttonHOC((disabled)=>({
    background:  disabled ? defaultColors.disabled : 'transparent',
    boxShadow: 'none',

    hover: disabled ? defaultColors.disabled : defaultColors.deleteBackground,
    childSx: {...MUIStyles.smallBold, color: defaultColors.delete}
}));

export const FailureButton = buttonHOC((disabled)=>({
    background:  disabled ? defaultColors.disabled : '#F3736C',
    boxShadow: 'none',

    hover: disabled ? defaultColors.disabled : darken('#F3736C', 0.2),
    childSx: {...MUIStyles.smallBold, color: '#fff'}
}));

export const PrimaryTransparentButton = buttonHOC((disabled)=>({
    background:  disabled ? defaultColors.disabled : 'transparent',
    boxShadow: 'none',
    hover: disabled ? defaultColors.disabled : defaultColors.primaryTransparent,
    childSx: {...MUIStyles.smallBold, color: colors.main}
}));


export const BlueTransparentButton = buttonHOC((disabled)=>({
    background:  disabled ? defaultColors.disabled : 'transparent',
    hover: disabled ? defaultColors.disabled : defaultColors.linkHover,
    focus:  defaultColors.focus,
    boxShadow: 'none',

    childSx: {...MUIStyles.mediumParagraph, color: defaultColors.link}

}));
// export const DeleteTransparentButton = ({children, buttonSx, onClick, sx, disabled, ...otherProps}) => {

//   return (
//     <Button onClick={onClick} disabled={disabled} {...otherProps} 
//     sx={{
//       backgroundColor: disabled ? defaultColors.disabled : 'transparent',
//       '&:hover': {
//         backgroundColor: disabled ? defaultColors.disabled : defaultColors.deleteBackground,
//       },
//       ...sx
//     }}>
//          <ButtonChild sx={{...MUIStyles.smallBold, color: defaultColors.delete, ...buttonSx}}>{children}</ButtonChild>

//     </Button>
//   );
// };




export const CloseButton = ({onClick}) => <PrimaryButton 
    onClick={onClick}
    sx={{width: '57px', height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 0.5, margin: 2, cursor: 'pointer'}}><Typography sx={MUIStyles.buttonText}>X</Typography></PrimaryButton>;

// export const FAQBlock = () => 


export default function AnimatedButton({startColor, }) {
    const [color, setColor] = React.useState('primary');

    const handleClick = () => {
        setColor(color === 'primary' ? 'secondary' : 'primary');
    };

    return (
        <Button
            onClick={handleClick}
            variant="contained"
            color={color}
            sx={{
                transition: 'background-color 0.5s ease', // Define the transition here
                //   If you want to use specific colors, you can replace color with backgroundColor
                backgroundColor: color === 'primary' ? 'blue' : 'green',
                '&:hover': {
                    backgroundColor: color === 'primary' ? 'darkblue' : 'darkgreen',
                },
            }}
        >
      Click me
        </Button>
    );
}


export const InvisibleButtonChild = ({children, sx, loading = false}) => typeof children === 'string' ? <Typography sx={{...MUIStyles.latoBoldParagraph, color: colors.text, ...sx}}>{children}</Typography> : children;


export const InvisibleButton = ({sx, onClick,childSx,children, isActive, ...rest}) => (
    <Button
        sx={{display: 'flex', 
            height: '64px',
            marginLeft: 2,
            
            alignItems: 'center', '&:focus': {backgroundColor: defaultColors.primaryMediumOpacity },
            '&:hover': {backgroundColor: defaultColors.primaryTransparent },
            '&:active': {
                // transform: 'scale(0.97)',
                backgroundColor: lighten(colors.main, 0.2), 
            },
            ...sx

        }}
        {...rest}
        onClick={onClick}
  
    >
        <InvisibleButtonChild sx={{...childSx}}>{children}</InvisibleButtonChild>
        {isActive && (
            <Box
                component="span"
                sx={{
                    position: 'absolute',
                    bottom: '10px', // Adjust this value to increase/decrease distance from text
                    left: 0,
                    right: 0,
                    height: '2px', // Adjust this value for thickness of underline
                    background: 'currentcolor', // Use the current text color
                }}
            />
        )}

    </Button>

);